/* eslint-disable */

import { TypedObject } from "../../../explore";
import { ActionRunMode } from "../CommonTypesV2";

export enum ObjectType {
  object_type_unset = "object_type_unset",
  entity_type = "entity_type",
  cohort_type = "cohort_type",
  entity = "entity",
  user_type = "user_type",
  widget_type = "widget_type",
  metric_type = "metric_type",
  operationalize_type = "operationalize_type",
  UNRECOGNIZED = "UNRECOGNIZED"
}

export enum AlertLifecycleType {
  alert_lifecycle_type_unset = "alert_lifecycle_type_unset",
  one_off = "one_off",
  repeated = "repeated",
  cumulative = "cumulative",
  UNRECOGNIZED = "UNRECOGNIZED"
}

export enum AlertStatus {
  alert_status_unset = "alert_status_unset",
  active = "active",
  closed = "closed",
  UNRECOGNIZED = "UNRECOGNIZED"
}

export enum Segment {
  segment_unset = "segment_unset",
  error = "error",
  insight = "insight",
  incident = "incident",
  audit = "audit",
  UNRECOGNIZED = "UNRECOGNIZED"
}

export enum SubSegment {
  subsegment_unset = "subsegment_unset",
  bizEntity_incident = "bizEntity_incident",
  userService_incident = "userService_incident",
  UNRECOGNIZED = "UNRECOGNIZED"
}

export interface AlertDetails {
  alertLifecycleType: AlertLifecycleType;
  status: AlertStatus;
  segment: Segment;
  subsegment: SubSegment;
  message: string;
  updateBody: boolean;
  entityType: string;
  userId: string;
  alertParams: Map<string, string>;
  alertParamsBool: Map<string, boolean>;
  alertParamsLong: Map<string, number>;
  alertParamsDouble: Map<string, number>;
  alertParamsArray: Map<string, ArrayList>;
  alertObjectParams: Map<string, ObjectId>;
  alertObjectListParams: Map<string, ObjectIdList>;
}

export interface AlertDetailsAlertParamsEntry {
  key: string;
  value: string;
}

export interface AlertDetailsAlertParamsBoolEntry {
  key: string;
  value: boolean;
}

export interface AlertDetailsAlertParamsLongEntry {
  key: string;
  value: number;
}

export interface AlertDetailsAlertParamsDoubleEntry {
  key: string;
  value: number;
}

export interface AlertDetailsAlertParamsArrayEntry {
  key: string;
  value: ArrayList | undefined;
}

export interface AlertDetailsAlertObjectParamsEntry {
  key: string;
  value: ObjectId | undefined;
}

export interface AlertDetailsAlertObjectListParamsEntry {
  key: string;
  value: ObjectIdList | undefined;
}

export interface ArrayList {
  value: string[];
}

export interface Action {
  /** possible values drilldown /... */
  actionType: string;
  actionUrl: string;
  debug: boolean;
  priority: number;
}

export interface ObjectId {
  id: string;
  name: string;
  subType: string;
  objectType: ObjectType;
}

export interface ObjectIdList {
  objectIdList: ObjectId[];
}

export interface SuggestResponse {
  values: ObjectId[];
  limitTruncated: boolean;
}

export interface SuggestRequest {
  fieldType: string;
  fieldValue: string;
  dependentFields: Map<string, string>;
}

export interface SuggestRequestDependentFieldsEntry {
  key: string;
  value: string;
}

export type AlertLifecycleStates = "triggered" | "updated" | "resolved";

export interface AlertSnapshot {
  alertLifecycleState: AlertLifecycleStates;
  alertLifecycleTimeMillis: string;
  objectStorePayload: {
    url: string;
  };
}

export interface AlertActionResponse {
  actionThread: AlertActionThreadRecord[];
}

export interface AlertActionThreadRecord {
  id: string;
  actionId: string;
  source_type: string;
  actionRunMode: ActionRunMode;
  integrationActionConfigId: string;
  connectorId: string;
  alertId: string;
  alertLifecycleTimeMillis: number;
  opConfigId: string;
  threadMillis: number;
  threadState: AlertLifecycleStates;
  componentIds: string[];
  threadParams: Record<string, string>;
  description: string;
  actionThreadSummary: ActionThreadSummary;
  drilldownUrl: string;
}

export interface ActionThreadSummary {
  actionResponse: ActionResponse;
  asyncActionResponse: ActionResponse[];
}

interface ActionResponse {
  runActionResponse?: RunActionResponse;
}

interface RunActionResponse {
  status: string;
  messages?: {
    values: string[];
  };
  response: Record<string, TypedObject>;
  requestContext: RequestContext;
}

interface RequestContext {
  refId: string;
  tenantId: string;
  sourceTypeId: string;
  additionalContext: Record<string, TypedObject>;
}
