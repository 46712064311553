export const LiteralUtils = {
  isNumber: (str: string | null | undefined): boolean => {
    if (!str) {
      return false;
    }

    if (str.includes(".")) {
      const numDecimals = str.split(".")[1].length;
      return parseFloat(str).toFixed(numDecimals) === str;
    }

    return parseInt(str, 10).toString() === str;
  },

  isOperator: (str: string | null | undefined): boolean => {
    if (!str) {
      return false;
    }
    return ["+", "-", "*", "/"].includes(str);
  },

  isSymbol: (str: string | null | undefined): boolean => {
    if (!str) {
      return false;
    }
    return ["(", ")"].includes(str);
  },

  isVariable: (str: string | null | undefined): boolean => {
    if (!str) {
      return false;
    }
    return /[a-z]/i.test(str);
  },

  isDecimal: (str: string | null | undefined): boolean => {
    if (!str) {
      return false;
    }
    return str === ".";
  }
};
