import { DataType } from "../../../../core";
import { TriageClustersResponse, UserServiceFieldSlice, WidgetData } from "../../explore";
import { AlertEventRecord } from "./alerts";

export interface InsightMemberShipResponse {
  incidentSeriesCount: number;
  totalSeriesCount: number;

  isSpike: boolean;
  isSpikePositive: boolean; // Used to show spike as green

  impactData: number;
  impactDataStr: string;
  data: number;
  metricName: string;
  timeshiftData: number;

  displayDataType: DataType;
}

// API to fetch incident series data, impact summary and cause summary
export enum GetIncidentInfoProjection {
  IMPACT_SUMMARY = "IMPACT_SUMMARY",
  CAUSE_SUMMARY = "CAUSE_SUMMARY",
  CAUSE_SUMMARY_ENGLISH = "CAUSE_SUMMARY_ENGLISH"
}

export interface GetIncidentInfoRequest {
  opConfigId: string;
  incidentId: string;
  projection: GetIncidentInfoProjection[];
}

export interface GetIncidentInfoResponse {
  impactSummary: ImpactSummary;
  causeSummary: TriageClustersResponse;
  entityLookupData: Record<string, string>;
}

export interface ImpactSummary {
  // oneOf
  widgetData?: WidgetData;
  impactData?: ImpactSummaryData;

  impactField: UserServiceFieldSlice;
}

export interface ImpactSummaryData {
  data: ImpactSummaryEntry[];
}

export interface ImpactSummaryEntry {
  tags: Record<string, string>;
  value: number;
  deviation: number;
}

export interface AlertDataQuery {
  startTimeMillis: number;
  endTimeMillis: number;
  opId?: string;
  useCaseId?: string;
  mtsId?: string;
  incidentId?: string;
  alertEvent?: AlertEventRecord;
  incidentIdList?: IncidentIdList;
  status?: string;
  pageSize: number;
  pageToken: string;
  includeOpMetadata: boolean;
  warmupCauseGraph: boolean;
  excludeCauseSummary: boolean;
}

export interface IncidentIdList {
  incidentIds: string[];
}

export interface OpAlertWarmupResponse {
  opId: string;
  incidentIdVsWarmUpResponse: Record<string, IncidentWarmupResponse>;
  failingWarmupCount: number;
  errorMessage: string;
}

export interface IncidentWarmupResponse {
  incidentId: string;
  mtsIdVsCauseGraphGenerationStatus: Record<string, boolean>;
  failingWarmupCount: number;
  pendingWarmupCount: number;
  successfulWarmupCount: number;
}
