import React, { useMemo, useCallback, forwardRef } from "react";
import { cx } from "emotion";
import { generateId } from "../../utils";
import { IncFaIcon } from "../../icons";
import { IncToolTip } from "../antd-components";
import { IncPillProps } from "./types";

export const IncPill = forwardRef<HTMLDivElement, IncPillProps>((props, ref) => {
  const {
    onRemove,
    actions,
    label,
    readonly,
    tooltipText,
    onClick: onLabelClick,
    enableClickWhenReadonly,
    className: pClassName = "",
    labelClass = "",
    ...htmlProps
  } = props;

  const enableClick = useMemo(() => {
    const readonlyClickEnabled = enableClickWhenReadonly ? true : !readonly;
    return !!onLabelClick && readonlyClickEnabled;
  }, [enableClickWhenReadonly, onLabelClick, readonly]);

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      if (enableClick && onLabelClick) {
        onLabelClick();
      }
      e.stopPropagation();
    },
    [enableClick, onLabelClick]
  );

  const title = useMemo(() => {
    if (typeof label === "string") {
      return label;
    }
    return undefined;
  }, [label]);

  const labelDiv = useMemo(() => {
    const className = cx("inc-pill--label", labelClass);
    const labelDiv = (
      <div
        className={className}
        title={title}
      >
        {label}
      </div>
    );
    if (tooltipText) {
      return (
        <IncToolTip
          placement="top"
          titleText={tooltipText}
        >
          {labelDiv}
        </IncToolTip>
      );
    }
    return labelDiv;
  }, [label, labelClass, title, tooltipText]);

  const actionsDivs = useMemo(() => {
    const acts = actions ? [...actions] : [];

    if (onRemove) {
      acts.push({
        icon: (
          <IncFaIcon
            className="inc-cursor-pointer"
            iconName="xmark"
            style={{ fontSize: 12 }}
          />
        ),
        onAction: onRemove
      });
    }

    return acts.map(act => {
      const key = generateId();
      const { icon, onAction, tooltipText } = act;
      const className = "inc-pill--action inc-flex-center";
      const style = { height: "100%" };

      const onClick = (e: React.MouseEvent) => {
        onAction(e);
        e.stopPropagation();
      };

      let iconDiv = (
        <div
          className={className}
          onClick={onClick}
          style={style}
        >
          {icon}
        </div>
      );
      if (tooltipText) {
        iconDiv = (
          <IncToolTip
            placement="top"
            titleText={tooltipText}
          >
            {iconDiv}
          </IncToolTip>
        );
      }
      return <div key={key}>{iconDiv}</div>;
    });
  }, [actions, onRemove]);

  const className = useMemo(
    () =>
      cx("inc-pill", {
        "inc-pill--readonly": readonly,
        [pClassName]: pClassName !== "",
        "inc-cursor-pointer": enableClick
      }),
    [enableClick, pClassName, readonly]
  );

  return (
    <div
      {...htmlProps}
      className={className}
      onClick={onClick}
      ref={ref}
    >
      {labelDiv}
      {!readonly && actionsDivs}
    </div>
  );
});
