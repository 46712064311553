import { FetchCallBackResult, FetchFn, InitialState, useDataFetch } from "@inception/ui";
import { useMemo, useCallback } from "react";
import { fetchEntityPropertiesForIds } from "../../utils";
import { MetricUserServiceFilters, WidgetConfigUtils } from "../../services/api/explore";

export const useEntityLookupByIds = (entityIds: string[], lazyFetch = false) => {
  const initialState = useMemo<InitialState<Record<string, string>, string>>(
    () => ({
      data: {},
      error: null,
      isError: false,
      isFetching: false,
      isSuccess: false
    }),
    []
  );

  const fetchFn = useCallback<FetchFn<Record<string, string>, string>>(async () => {
    const result: FetchCallBackResult<Record<string, string>, string> = {
      data: {},
      error: null,
      isError: false,
      isSuccess: false
    };

    try {
      if (entityIds.length > 0) {
        const entityPropertiesMap = await fetchEntityPropertiesForIds(new Set(entityIds), 0, Date.now());

        const entityLookup: Record<string, string> = {};
        entityPropertiesMap.forEach((value, key) => {
          entityLookup[key] = value.name;
        });

        result.data = entityLookup;
        result.isSuccess = true;
      } else {
        result.data = {};
        result.isSuccess = true;
      }
    } catch (err) {
      result.isError = true;
      result.error = err instanceof Error ? err.message : String(err);
    }

    return result;
  }, [entityIds]);

  return useDataFetch(fetchFn, initialState, lazyFetch);
};

export const useFiltersEntityLookup = (filters?: MetricUserServiceFilters, lazyFetch = false) => {
  const allEntityIds = useMemo(() => {
    const ids: string[] = [];

    if (!filters) {
      return ids;
    }

    Object.values(filters).forEach(filterList => {
      if (filterList.expressionTree) {
        ids.push(...WidgetConfigUtils.getEntityIdsFromFilterTree(filterList.expressionTree));
      }
      if (filterList.userServiceFilters) {
        const expressions = filterList.userServiceFilters.flatMap(x => x.userServiceFilterExpressions);
        ids.push(...WidgetConfigUtils.getEntityIdsFromFilterExpressions(expressions));
      }
    });

    return [...new Set(ids)].filter(Boolean);
  }, [filters]);

  return useEntityLookupByIds(allEntityIds, lazyFetch);
};
