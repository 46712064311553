import { generatePath } from "react-router-dom";
import { RawTimeRange } from "../platform/core/hooks/time-range/types";

export const routePaths = {
  root: "/",
  resetPassword: "/user/password/reset",
  forgotPassword: "/user/password/send-reset-email",
  signUpInvited: "/invite/:code",
  login: "/login",
  logout: "/logout",
  home: "/home",
  dashboardsCreate: "/dashboards/create",
  dashboardsEditOrView: "/dashboards/:mode(edit|add|view|clone)?/:dashId?",
  dashboardsList: "/dashboards/list",
  dashboards: "/dashboards",
  sharedDashboard: "/share-dashboard/:dashId",
  integrations: "/integrations",
  integrationsGeneric: "/integrations/setup",
  integrationsHome: "/integrations/home",
  integrationsAvailable: "/integrations/available",
  integrationsConnectedBizSystems: "/integrations/connected/biz-systems",
  integrationsConnectedEventSources: "/integrations/connected/event-sources",
  integrationsConnectedKnowledgeBases: "/integrations/connected/knowledge-bases",
  integrationsJira: "/integrations/jira",
  integrationsGitlab: "/integrations/gitlab",
  integrationsSalesforce: "/integrations/salesforce",
  integrationsZuora: "/integrations/zuora",
  integrationsDatabase: "/integrations/database",
  integrationsWebhook: "/integrations/webhook",
  integrationsCsv: "/integrations/csv",
  integrationsElastic: "/integrations/event-sources/elastic",
  integrationsMapUserService: "/integrations/map-user-service",
  integrationsMapUserService2: "/integrations/map-user-service-2",
  traces: "/traces",
  traceDetails: "/trace/details",
  configurationAddOrEdit: "/configuration/:mode(edit|add)",
  configurationList: "/configuration/list",
  configuration: "/configuration",
  settings: "/admin/settings",
  users: "/admin/users",
  partnersList: "/admin/partners",
  tokens: "/admin/tokens",
  kbSummary: "/admin/knowledge-base-summary",
  entityCreator: "/entity-create",
  featureFlags: "/feature-flags",
  widgetsAlertSummary: "/alerts/widget/summary",
  manageBusinessEntity: "/manage-data/business-entity",
  listBusinessEntities: "/manage-data/business-entity/entities/:entityTypeId",
  manageBusinessModel: "/manage-data/business-model",
  tenants: "/admin/tenants",
  allTokens: "/admin/all/tokens",
  allUsers: "/admin/all/users",
  operationalize: "/operationalize",
  operationalizeHome: "/operationalize/home",
  demo: "/demo",
  createJourney: "/journey/create",
  demoSelector: "/demo-selector",
  businessCatalog: "/business-catalog",
  autoDiscovery: "/auto-discovery",
  commonAlerts: "/common-alerts",
  userOnboarding: "/user-onboarding",
  triage2: "/triage2/:widgetId",
  alerts: "/alerts",
  autoDiscoverEvents: "/auto-discover-events",
  autoDiscoverEventsTest: "/auto-discover-events-test",
  messages: "/messages",
  messagesV0: "/messages/v0",
  admin: "/admin",
  adminSso: "/admin/sso",
  identity: "/admin/identity",
  bizJourneys: "/journeys",
  bizFlow: "/business-flow",
  onboarding: "/onboarding",
  chatV2: "/chat",
  bizProcess: "/biz-process",
  playground: "/playground/opId/:opId/:entityTypeName",
  companySelector: "/company-selector",
  demoDataGenerator: "/demo-data-generator",
  dataOnboarding: "/data-onboarding",
  dataOnboardingV2: "/data-onboarding-v2",
  dataOnboardingV3: "/data-onboarding-v3",
  dataToCopilot: "/data-to-copilot",
  groupedIncidents: "/grouped-incidents/:groupedIncidentId",
  calendarEvents: "/calendar",
  insightsHome: "/insights",
  dataSettings: "/data-settings",
  knowledgeBaseSettings: "/knowledge-base-settings",
  memberSettings: "/member-settings",
  connectDataSources: "/data-sources",
  report: "/report/:reportId",
  sandboxDiscovery: "/customize-sandbox",
  uberOperationalize: "/uber-operationalize",
  dataModelling: "/data-modelling",
  companySelectorV2: "/company-selector-v2",
  dataModellingV5: "/userservice-mapping"
};

export const getRouteUrl = (route: string, routeParams: Record<string, string>): string =>
  generatePath(route, routeParams);

export const defaultTimeRangeByRoute: Record<string, RawTimeRange> = {
  traces: {
    from: "now-5m",
    to: "now"
  }
};
