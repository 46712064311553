import { CreateCopilot } from "./create-copilot";
import { Login } from "./login";
import { Triage } from "./triage";
import { UseCaseChat } from "./usecase-chat";

export const FeatureCypressConstants = {
  Login,
  CreateCopilot,
  Triage,
  UseCaseChat
};
