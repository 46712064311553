import { getBizEntityUrl } from "../utils";

export const UseCaseChat = {
  attributes: {
    suggestionPill: "chat-suggestion-pill",
    recommendationContainer: "chat-recommendation"
  },
  requestUrls: {
    feedCommands: getBizEntityUrl("usecase/*/user/feedCommands"),
    feedList: getBizEntityUrl("usecase/*/user/feedList"),
    fetchSuggestions: getBizEntityUrl("suggestions?*"),
    fetchOpSchema: getBizEntityUrl("op10ze/*/schema?*"),

    continueChat: getBizEntityUrl("chat/continue?*"),
    continueChatStatus: getBizEntityUrl("chat/continue/status?*")
  },
  requestKeysToIgnoreIntercept: ["cohortPoll"]
};
