import { cloneDeep, isEmpty } from "lodash";
import {
  BizDataQuery,
  MetricUserServiceFilters,
  WidgetQuerySchema,
  UserServiceFilterExpression,
  UserServiceFilterExpressionTree,
  UserServiceFilterNode,
  UserServiceFilterList,
  WidgetConfigUtils,
  LogicalOperator
} from "../../services/api/explore";

export class OperationalizeFilterUtils {
  /**
   * Processes and merges filters for a BizDataQuery, handling both editable and non-editable filters
   * @param bizDataQuery - The original BizDataQuery to be processed
   * @param eventFilters - Editable filters to be applied
   * @param querySchema - Optional widget query schema. If not provided, will be fetched
   * @returns Object containing:
   *  - bizDataQuery: Updated BizDataQuery with merged filters
   *  - newFilters: Object containing matched and unmatched filters.
   */
  static async getBizDataQueryWithFilters(
    pBizDataQuery: BizDataQuery,
    eventFilters: MetricUserServiceFilters,
    querySchema?: WidgetQuerySchema[]
  ) {
    const bizDataQuery = cloneDeep(pBizDataQuery);

    const { bizDataQuery: bizDataQueryWithWidgetConfig, querySchema: widgetQuerySchema } =
      await WidgetConfigUtils.getWidgetConfigBasedBizDataQueryWithQuerySchema(bizDataQuery);

    if (!querySchema) {
      querySchema = widgetQuerySchema;
    }

    const matchedQuerySchema = querySchema?.find(
      qs =>
        qs.metricId ===
        (bizDataQueryWithWidgetConfig.sliceSpec.metricId ||
          bizDataQueryWithWidgetConfig.sliceSpec.buildingBlockConfigId)
    );

    // Process TopLevel filters
    const { matchedFilters: matchedTopLevelFilters, unMatchedFilters: unmatchedTopLevelFilters } =
      this.matchEventFiltersForMetric(matchedQuerySchema, eventFilters);

    // Process widgetConfig filters
    const widgetConfigFilters = bizDataQueryWithWidgetConfig.widgetConfig?.metricUserServiceFilters || {};
    const { matchedFilters: matchedWidgetConfigFilters } = this.matchEventFiltersForMetric(
      matchedQuerySchema,
      widgetConfigFilters
    );

    // Update bizDataQuery
    bizDataQuery.metricUserServiceFilters = this.mergeMetricUserServiceFilters(
      bizDataQuery.metricUserServiceFilters,
      matchedTopLevelFilters
    );

    // new Filters is used to show the matched and unmatched pills so it should have all the matched and unmatched filters
    return {
      bizDataQuery: bizDataQuery,
      newFilters: {
        matchedFilters: this.mergeMetricUserServiceFilters(matchedTopLevelFilters, matchedWidgetConfigFilters),
        unMatchedFilters: unmatchedTopLevelFilters
      }
    };
  }

  /**
   * Matches event filters for a specific metric based on the query schema
   * @param metricQuerySchema - Widget query schema containing metric information
   * @param eventFilters - Filters to be matched
   * @returns Object containing matched and unmatched filters
   */
  static matchEventFiltersForMetric(metricQuerySchema: WidgetQuerySchema, eventFilters: MetricUserServiceFilters) {
    if (isEmpty(metricQuerySchema)) {
      return {
        matchedFilters: {},
        unMatchedFilters: eventFilters
      };
    }

    let eventIdsToMatch: string[] = [];
    const { metricId } = metricQuerySchema;

    if (!isEmpty(metricQuerySchema?.sourceUserServiceField)) {
      eventIdsToMatch = metricQuerySchema.sourceUserServiceField.userServices.map(
        service => service.userServiceEntityId
      );
    }

    if (!isEmpty(metricQuerySchema?.componentSourceFields)) {
      eventIdsToMatch = [
        ...eventIdsToMatch,
        ...Object.values(metricQuerySchema.componentSourceFields).flatMap(sourceUserServiceField =>
          sourceUserServiceField.userServices.map(service => service.userServiceEntityId)
        )
      ];
    }

    return this.matchEventFiltersForEventTypeId(eventFilters, eventIdsToMatch, metricId);
  }

  /**
   * Matches event filters for a specific event type
   * @param eventFilters - Filters to be matched
   * @param eventIdsToMatch - Array of event type IDs to match against
   * @param metricId - Metric ID
   * @returns Object containing matched and unmatched filters categorized by metric ID
   */
  static matchEventFiltersForEventTypeId(
    eventFilters: MetricUserServiceFilters,
    eventIdsToMatch: string[],
    metricId: string
  ) {
    const matchedFilters: MetricUserServiceFilters = {};
    const unMatchedFilters: MetricUserServiceFilters = {};

    matchedFilters[metricId] = {};
    unMatchedFilters[metricId] = {};

    Object.values(eventFilters).forEach(filterList => {
      const matchedFilterList: UserServiceFilterList = {
        userServiceFilters: [],
        expressionTree: null
      };
      const unmatchedFilterList: UserServiceFilterList = {
        userServiceFilters: [],
        expressionTree: null
      };

      if (filterList.userServiceFilters) {
        const userServiceFilterExpressions = filterList.userServiceFilters.flatMap(x => x.userServiceFilterExpressions);
        const { matchedExpressions, unMatchedExpressions } = this.matchEventExpressionsForEventTypeId(
          eventIdsToMatch,
          userServiceFilterExpressions
        );

        if (matchedExpressions.length > 0) {
          matchedFilterList.userServiceFilters = [
            {
              userServiceFilterExpressions: matchedExpressions
            }
          ];
        }
        if (unMatchedExpressions.length > 0) {
          unmatchedFilterList.userServiceFilters = [
            {
              userServiceFilterExpressions: unMatchedExpressions
            }
          ];
        }
      }

      if (filterList.expressionTree) {
        const { matchedExpressionTree, unMatchedExpressionTree } = this.matchEventExpressionTreeForEventTypeId(
          eventIdsToMatch,
          filterList.expressionTree
        );

        if (Object.keys(matchedExpressionTree).length > 0) {
          matchedFilterList.expressionTree = matchedExpressionTree;
        }
        if (Object.keys(unMatchedExpressionTree).length > 0) {
          unmatchedFilterList.expressionTree = unMatchedExpressionTree;
        }
      }

      if (matchedFilterList.expressionTree || matchedFilterList.userServiceFilters?.length) {
        matchedFilters[metricId] = matchedFilterList;
      }
      if (unmatchedFilterList.expressionTree || unmatchedFilterList.userServiceFilters?.length) {
        unMatchedFilters[metricId] = unmatchedFilterList;
      }
    });

    return {
      matchedFilters,
      unMatchedFilters
    };
  }

  /**
   * Matches individual filter expressions against an event type
   * @param eventIdsToMatch - Array of event type IDs to match against
   * @param userServiceFilterExpressions - Array of filter expressions to match
   * @returns Object containing matched and unmatched expressions
   */
  static matchEventExpressionsForEventTypeId(
    eventIdsToMatch: string[],
    userServiceFilterExpressions: UserServiceFilterExpression[]
  ) {
    const matchedExpressions: UserServiceFilterExpression[] = [];
    const unMatchedExpressions: UserServiceFilterExpression[] = [];

    userServiceFilterExpressions.forEach(userServiceFilterExpression => {
      if (this.checkIfEventTypeMatches(eventIdsToMatch, userServiceFilterExpression)) {
        matchedExpressions.push(userServiceFilterExpression);
      } else {
        unMatchedExpressions.push(userServiceFilterExpression);
      }
    });

    return {
      matchedExpressions,
      unMatchedExpressions
    };
  }

  /**
   * Matches an expression tree against an event type
   * @param eventIdsToMatch - Array of event type IDs to match against
   * @param expressionTree - Expression tree to process
   * @returns Object containing matched and unmatched expression trees
   */
  static matchEventExpressionTreeForEventTypeId(
    eventIdsToMatch: string[],
    expressionTree: UserServiceFilterExpressionTree
  ) {
    const matchedNodes: UserServiceFilterNode[] = [];
    const unmatchedNodes: UserServiceFilterNode[] = [];

    expressionTree.filterNodes.forEach(node => {
      if (node.expression) {
        if (this.checkIfEventTypeMatches(eventIdsToMatch, node.expression)) {
          matchedNodes.push(node);
        } else {
          unmatchedNodes.push(node);
        }
      } else if (node.expressionTree) {
        const { matchedExpressionTree, unMatchedExpressionTree } = this.matchEventExpressionTreeForEventTypeId(
          eventIdsToMatch,
          node.expressionTree
        );

        // Only add if we have a non-empty expressionTree
        if (matchedExpressionTree && matchedExpressionTree.filterNodes.length > 0) {
          matchedNodes.push({ expressionTree: matchedExpressionTree });
        }
        if (unMatchedExpressionTree && unMatchedExpressionTree.filterNodes.length > 0) {
          unmatchedNodes.push({ expressionTree: unMatchedExpressionTree });
        }
      }
    });

    // Always return an expression tree with empty filterNodes instead of null
    const baseExpressionTree: UserServiceFilterExpressionTree = {
      logicalOperator: expressionTree.logicalOperator,
      filterNodes: []
    };

    return {
      matchedExpressionTree:
        matchedNodes.length > 0
          ? {
              ...baseExpressionTree,
              filterNodes: matchedNodes
            }
          : baseExpressionTree,
      unMatchedExpressionTree:
        unmatchedNodes.length > 0
          ? {
              ...baseExpressionTree,
              filterNodes: unmatchedNodes
            }
          : baseExpressionTree
    };
  }

  /**
   * Checks if a filter expression matches any of the event types
   * @param eventIdsToMatch - Array of event type IDs to check
   * @param userServiceFilterExpression - Filter expression to check
   * @returns boolean indicating if the expression matches any of the event types
   */
  static checkIfEventTypeMatches(eventIdsToMatch: string[], userServiceFilterExpression: UserServiceFilterExpression) {
    const { field } = userServiceFilterExpression;

    if (field.allUserService) {
      return true;
    }

    return field.userServices?.some(userService => eventIdsToMatch.includes(userService.userServiceEntityId)) || false;
  }

  /**
   * Combines multiple metric filters into a single merged result.
   * Groups and merges filters by their metric IDs, with each group being combined using AND logic.
   *
   * @param filters - Array of MetricUserServiceFilters objects to be merged
   * @returns A merged MetricUserServiceFilters object where each metric ID contains combined filters
   * @example
   * Input: [
   *   { metricA: filterList1 },
   *   { metricA: filterList2, metricB: filterList3 }
   * ]
   * Output: {
   *   metricA: { expressionTree: combined(filterList1, filterList2) },
   *   metricB: { expressionTree: combined(filterList3) }
   * }
   */
  static mergeMetricUserServiceFilters = (...filters: MetricUserServiceFilters[]): MetricUserServiceFilters => {
    if (!filters.length) {
      return {};
    }

    // Filter out null/undefined filters first
    const validFilters = filters.filter(Boolean);
    if (validFilters.length === 0) {
      return {};
    }

    const metricIds = new Set<string>();
    validFilters.forEach(filter => {
      Object.keys(filter).forEach(id => metricIds.add(id));
    });

    const result: MetricUserServiceFilters = {};

    metricIds.forEach(metricId => {
      // Safely collect all filter lists for this metric ID
      const userServiceFilterListArray = validFilters.reduce((acc, filter) => {
        if (filter && filter[metricId]) {
          acc.push(filter[metricId]);
        }
        return acc;
      }, []);

      if (userServiceFilterListArray.length === 0) {
        return;
      }

      const mergedExpressionTree: UserServiceFilterExpressionTree = {
        logicalOperator: LogicalOperator.AND,
        filterNodes: []
      };

      userServiceFilterListArray.forEach(userServiceFilterList => {
        const converted = this.convertToExpressionTree(userServiceFilterList);
        mergedExpressionTree.filterNodes.push(...converted.filterNodes);
      });

      result[metricId] = {
        expressionTree: mergedExpressionTree
      };
    });

    return result;
  };

  /**
   * Converts a UserServiceFilterList into an expression tree structure.
   * The conversion follows these rules:
   * 1. For multiple userServiceFilters: Creates an OR group containing AND groups of expressions
   * 2. For single userServiceFilter: Creates a single AND group of expressions
   * 3. For existing expressionTree: Either spreads nodes (if AND) or wraps tree (if OR)
   *
   * @param filterList - The UserServiceFilterList to convert
   * @returns An expression tree representing the filter logic
   * @example
   * // Multiple filters case:
   * Input: { userServiceFilters: [filter1, filter2] }
   * Output: {
   *   logicalOperator: 'AND',
   *   filterNodes: [{
   *     expressionTree: {
   *       logicalOperator: 'OR',
   *       filterNodes: [
   *         { expressionTree: { logicalOperator: 'AND', filterNodes: [...filter1] } },
   *         { expressionTree: { logicalOperator: 'AND', filterNodes: [...filter2] } }
   *       ]
   *     }
   *   }]
   * }
   */
  static convertToExpressionTree = (filterList: UserServiceFilterList): UserServiceFilterExpressionTree => {
    const expressionTree: UserServiceFilterExpressionTree = {
      logicalOperator: LogicalOperator.AND,
      filterNodes: []
    };

    if (filterList.userServiceFilters?.length) {
      let subTree: UserServiceFilterExpressionTree;

      if (filterList.userServiceFilters.length > 1) {
        // Multiple userServiceFilters are combined with OR
        subTree = {
          logicalOperator: LogicalOperator.OR,
          filterNodes: []
        };

        filterList.userServiceFilters.forEach(usf => {
          const andGroup = {
            logicalOperator: LogicalOperator.AND,
            filterNodes: usf?.userServiceFilterExpressions?.map(expr => ({
              expression: expr
            }))
          };
          subTree.filterNodes.push({ expressionTree: andGroup });
        });
      } else {
        // Single userServiceFilter becomes an AND group
        const usf = filterList.userServiceFilters[0];
        subTree = {
          logicalOperator: LogicalOperator.AND,
          filterNodes: usf?.userServiceFilterExpressions?.map(expr => ({
            expression: expr
          }))
        };
      }

      expressionTree.filterNodes.push({ expressionTree: subTree });
    } else if (filterList.expressionTree) {
      if (filterList.expressionTree.logicalOperator === LogicalOperator.AND) {
        expressionTree.filterNodes.push(...filterList.expressionTree.filterNodes);
      } else {
        expressionTree.filterNodes.push({ expressionTree: filterList.expressionTree });
      }
    }

    return expressionTree;
  };
}

export type FilterResult = {
  matchedFilters: MetricUserServiceFilters;
  unMatchedFilters: MetricUserServiceFilters;
};
